import { joinStr } from '@utils'
import { pushDataLayer, pushNextPageDataLayer } from '../index'
import { PageViewLayer } from '@component/dataLayer'
import { get } from 'lodash-es'

export default function PageViewDataLayer({
  data
}) {

  return (
    <PageViewLayer data={{
      'event': 'page_view',
      'vertical': get(data, 'data.dataLayer.vertical'),
      'page_type': get(data, 'data.dataLayer.pageType'),
      'vertical_type': get(data, 'data.dataLayer.verticalType')
    }}></PageViewLayer>
  )
}
export const hubpageDataLayer = {
  findProperty: {
    priceChange(label) {
      pushDataLayer({
        'event': 'analytics_track',
        'event_journey': 'find properties',
        'event_interaction': 'change price',
        'event_type': label
      })
    },

    regionChange(label) {
      pushDataLayer({
        'event': 'analytics_track',
        'event_journey': 'find properties',
        'event_interaction': 'change region',
        'event_type': label
      })
    },

    itemClick(pageType, verticalType) {
      if (pageType) {
        pushNextPageDataLayer({
          'ref_page_element': joinStr(['find_properties', pageType, verticalType], '_'),
        })
      }

      pushDataLayer({
        'event': 'ga4_event',
        'event_name': 'perform_search',
        'search_method': 'region_modal'
      })
    }
  },

  exploreByRegion: {
    itemClick() {
      pushDataLayer({
        'event': 'ga4_event',
        'event_name': 'perform_search',
        'search_method': 'region_modal'
      })
    }
  },

  contentBox: {
    itemClick(items) {
      pushDataLayer({
        'ecommerce': null
      })
      pushDataLayer({
        'event': 'view_item_list',
        'ecommerce': { items }
      })
    }
  }
}