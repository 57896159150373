import Image from 'next/image'

export default function CircleCard({
  data
}) {

  return (
    <div className='flex justify-center'>
      <a
        href={data.link}
        target="_blank"
      >
        <Image
          src={data.cover}
          width={75}
          height={75}
          alt={data.title}
          unoptimized
          className='w-75 h-75 rounded-half block mx-auto'
        />
        <div className='text-center mt-16'>{data.title}</div>
      </a>
    </div>
  )
}